<template>
  <div class="w-100 d-flex flex-wrap align-items-center justify-content-center">
    <h1> Payment Success </h1>
    <div class="flex-break" />
    <h1> {{ $route.params.id }} </h1>
  </div> 
</template>

<script>
export default {
  created() {
    this.$toast(
      {
        component: this.$toastContent,
        props: {
          title: 'Business Card Payment',
          icon: 'CheckCircleIcon',
          text: 'Payment Success',
          variant: 'success',
        },
      },
      { timeout: this.$longestTimeout },
    )

    this.$router.push({ name: 'business-cards-list' })
  },
}
</script>

<style>

</style>